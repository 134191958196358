<template>
	<el-container>
		<div style="height: 100px !important;"></div>
		<div style="height: 100px !important; width: 1920px; position: fixed; z-index: 1002; background-color: #FFFFFF;box-shadow: 12px 10px 21px 0px rgba(63,63,63,0.26);">
			<el-header>
				<div>
					<img src="../../assets/logo.png" alt="" style="width: 212px; height: 70px;">
				</div>
				<div>
					<el-menu
					  :default-active="currentPage.toString()"
					  class="el-menu-demo apiview"
					  mode="horizontal"
					  @select="handleSelect"
					  background-color="#FFFFFF"
					  text-color="#020202"
					  active-text-color="#FF0000">
						<el-menu-item index="1" style="padding: 0 20px; margin-right: 40px;">首页</el-menu-item>
						<el-menu-item index="2" style="padding: 0 20px; margin-right: 40px;">产品</el-menu-item>
						<el-menu-item index="3" style="padding: 0 20px; margin-right: 40px;">行业解决方案</el-menu-item>
						<el-menu-item index="4" style="padding: 0 20px; margin-right: 40px;">渠道合作</el-menu-item>
						<el-menu-item index="5" style="padding: 0 20px; margin-right: 40px;">软件下载</el-menu-item>
						<el-submenu index="6" style="padding: 0 20px; margin-right: 40px;" popper-append-to-body popper-class="popperclass">
							<template slot="title">帮助中心</template>
							<el-submenu index="6-1" popper-append-to-body popper-class="popperclass2">
								<template slot="title"><div style="font-size: 22px;font-family: AlibabaPuHuiTi_2_55_Regular;font-weight: normal;color: #020202;">操作中心</div></template>
								<el-menu-item index="6-1-1" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">常用单据举例</el-menu-item>
								<el-menu-item index="6-1-2" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">新手操作指引</el-menu-item>
								<el-menu-item index="6-1-3" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">帮助手册【旗舰版】</el-menu-item>
								<el-menu-item index="6-1-4" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">帮助手册【专业版】</el-menu-item>
								<el-menu-item index="6-1-5" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">帮助手册【业务版】</el-menu-item>
								<el-menu-item index="6-1-6" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">帮助手册【标准版】</el-menu-item>
								<el-menu-item index="6-1-7" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">帮助手册【手机版】</el-menu-item>
								<el-menu-item index="6-1-8" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">行业应用示例</el-menu-item>
							</el-submenu>
							<el-submenu index="6-2" popper-append-to-body popper-class="popperclass2">
								<template slot="title"><div style="font-size: 22px;font-family: AlibabaPuHuiTi_2_55_Regular;font-weight: normal;color: #020202;">视频演示</div></template>
								<el-menu-item index="6-2-1" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">系统介绍</el-menu-item>
								<el-menu-item index="6-2-2" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">操作演示</el-menu-item>
								<el-menu-item index="6-2-3" style="height: 40px; display: flex; justify-content: center;
					align-items: center;font-size: 20px;font-weight: normal;font-family: AlibabaPuHuiTi_2_55_Regularcolor: #020202;">行业应用</el-menu-item>
							</el-submenu>
						</el-submenu>
						<el-menu-item index="7" style="padding: 0 20px;">关于我们</el-menu-item>
					</el-menu>
				</div>
				<img src="../../assets/image/address.png" :src="require('../../assets/image/hot.gif'.replace('on','off'))"
				style="width:40px;height:23px; position: absolute; top:15px; left: 1085px; z-index: 1000;">
			</el-header>
			<div style="height: 100px !important;z-index: 2000;">
				<el-carousel height="50px" direction="vertical" :autoplay="true" :interval="5000">
					<div style="display: flex; align-items: center;justify-content: flex-start;">
						<img src="../../assets/image/tongzhi.png" alt="" width="25px" style="margin: 12px 0 0 12px;">
						<el-carousel-item v-for="item in message" :key="item">
							<h3 style="padding-left: 50px; line-height: 50px; margin: 0; color: #FF0000;" class="medium">{{ item.str }}</h3>
						</el-carousel-item>
					</div>
				</el-carousel>
			</div>
			
		</div>
		<el-main style="padding: 0px;">
			<keep-alive>
				<router-view></router-view>
			</keep-alive>
		</el-main>
		<div class="sidebar" @mouseenter="buttonEnter(1)" @mouseleave="buttonLeave(1)">
			<div v-if="sidebarflag1">
				<img src="../../assets/image/cusser2.png">
			</div>
			<div v-else>
				<img src="../../assets/image/cusser1.png">
			</div>
			<div>客服热线</div>
			<div class="showbox1" v-if="!sidebarflag1">
				<div style="margin:27px 0 0 38px;">
					服务热线：
					<br>0731-5861 5788
				</div>
			</div>
		</div>
		<div class="sidebar" style="top: 631px;" @mouseenter="buttonEnter(2)" @mouseleave="buttonLeave(2)">
			<div v-if="sidebarflag2" >
				<img src="../../assets/image/cooperateother.png">
			</div>
			<div v-else>
				<img src="../../assets/image/cooperatewhite.png">
			</div>
			<div>商务合作</div>
			<div class="showbox2" v-if="!sidebarflag2">
				<img src="../../assets/image/szwx.png">
				<div>
					138 0732 3918（孙老师）
				</div>
			</div>
		</div>
		<div class="sidebar2" style="top: 717px;" v-if="sidebarflag3" @click="gotop()">
			<img src="../../assets/image/up.png">
		</div>
		<el-footer height="371px">
			<div class="footer">
				<div style="display: flex; align-items: center; justify-content: center;">
					<div style="width: 960px; height: 300px; ">
						<div class="footertext1">
							壹本账全国运营中心
						</div>
						<div class="footertext2" style="display: flex; align-items: center; margin: 17px 0 0 213px;">
							<img src="../../assets/image/address.png" style="width: 30px; height:30px;">
							<div style="margin-left: 9px;">公司总部：湖南省湘潭市岳塘区天元湘潭中心A座29楼</div>
						</div>
						<div class="footertext2" style="display: flex; align-items: center; margin: 17px 0 0 213px;">
							<img src="../../assets/image/phone.png" style="width: 30px; height: 30px;">
							<div style="margin-left: 9px;">合作专线：138 0732 3918（孙老师）</div>
						</div>
						<div class="footertext2" style="display: flex; align-items: center;  margin: 17px 0 0 213px;">
							<img src="../../assets/image/phone2.png" style="width: 30px; height: 30px;">
							<div style="margin-left: 9px;">服务热线：0731-5861 5788</div>
						</div>
					</div>
					<div div style="width: 960px; height: 300px; display: flex; align-items: center; margin: 7px 0 0 213px;">
						<div class="footertext2" style="width:213px;">
							<img src="../../assets/image/sunzongwx.png" style="width: 120px; height: 120px; margin-left: 46px;">
							<div style="margin-top: 14px; text-align: center;">扫码添加孙老师微信</div>
						</div>
						<div class="footertext2" style="width:213px;">
							<img src="../../assets/image/gzh.png" style="width: 120px; height: 120px; margin-left: 46px;">
							<div style="margin-top: 14px; text-align: center;">壹本账公众号</div>
						</div>
						<div class="footertext2" style="width:213px;">
							<img src="../../assets/image/sph.png" style="width: 120px; height: 120px; margin-left: 46px;">
							<div style="margin-top: 14px; text-align: center;">壹本账视频号</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer2">
				©2023 湖南壹本账信息科技有限公司<span v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"/></span>
				<a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备18016864号-2</a>
			</div>
		</el-footer>
	</el-container>
</template>

<script>
	import {
		post
	} from '../../network/request.js'
	export default {
		name: "home",
		data() {
			return {
				message: [{id:'0',str:'壹本账'}],
				currentPage: 1,
				sidebarflag1: true,
				sidebarflag2: true,
				sidebarflag3: false,
				scrollheight: 0
			}
		},
		methods:{
			handleSelect(key, keyPath) {
				if (key == '6-1-1') {
					window.open("https://fx.beebox.cc/eman/pub/km7ph1", '_blank');
				} else if (key == '6-1-2') {
					window.open("https://fx.beebox.cc/eman/pub/wwyiy4", '_blank');
				} else if (key == '6-1-3') {
					window.open("https://fx.beebox.cc/eman/pub/zpa29a", '_blank');
				} else if (key == '6-1-4') {
					window.open("https://fx.beebox.cc/eman/pub/009adc", '_blank');
				} else if (key == '6-1-5') {
					window.open("https://fx.beebox.cc/eman/pub/fopykm", '_blank');
				} else if (key == '6-1-6') {
					window.open("https://fx.beebox.cc/eman/pub/ibqsgy", '_blank');
				} else if (key == '6-1-7') {
					window.open("https://fx.beebox.cc/eman/pub/inxxjy", '_blank');
				} else if (key == '6-1-8') {
					window.open("https://fx.beebox.cc/eman/pub/qubgmg", '_blank');
				} else if (key == '6-2-3') {
					window.open("https://fx.beebox.cc/eman/pub/3supxi", '_blank');
				} else if (key == '6-2-2') {
					window.open("https://fx.beebox.cc/eman/pub/n69n8g", '_blank');
				} else if (key == '6-2-1') {
					window.open("https://fx.beebox.cc/eman/pub/7gnzx2", '_blank');
				} 
				if (this.currentPage == key && this.currentPage != 6) {
					return
				} else {
					this.currentPage = key
				}
				if (key == 1) {
					this.$router.push({
						path: "/home/start"
					})
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				} else if (key == 2) {
					this.$router.push({
						path: "/home/product"
					})
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				} else if (key == 3) {
					this.$router.push({
						path: "/home/solution"
					})
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				} else if (key == 4) {
					this.$router.push({
						path: "/home/cooperate"
					})
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				} else if (key == 5) {
					this.$router.push({
						path: "/home/download"
					})
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				} else if (key == 7) {
					this.$router.push({
						path: "/home/about"
					})
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				}
			},
			
			gotop() {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
		
			buttonEnter(index) {
				this.['sidebarflag' + index] = false;
			},
			buttonLeave(index) {
				this.['sidebarflag'+ index] = true;
			},
			
			scrolling() {
				// 获取当前滚动条向下滚动的距离
				let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				this.scrollheight = scrollTop;
			},
		},
		beforeCreate() {

		},
		created() {
			post({
				url: "/getMessage",
				data: {},
				header: {
					'content-type': 'applicition/json'
				},
			}).then(res => {
				this.message = res.data.data;
			}).catch(err => {
				
			})
		},
		beforeMount() {
			let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			if (this.$route.name == 'start' && scrollTop > 800) {
				this.sidebarflag3 = true;
			} else if (this.$route.name == 'start' && scrollTop <= 800) {
				this.sidebarflag3 = false;
			} else if (this.$route.name == 'product' && scrollTop > 500) {
				this.sidebarflag3 = true;
			} else if (this.$route.name == 'product' && scrollTop <= 500) {
				this.sidebarflag3 = false;
			} else if (this.$route.name == 'solution' && scrollTop > 500) {
				this.sidebarflag3 = true;
			} else if (this.$route.name == 'solution' && scrollTop <= 500) {
				this.sidebarflag3 = false;
			} else if (this.$route.name == 'cooperate' && scrollTop > 1200) {
				this.sidebarflag3 = true;
			} else if (this.$route.name == 'cooperate' && scrollTop <= 1200) {
				this.sidebarflag3 = false;
			} else if (this.$route.name == 'download' && scrollTop > 800) {
				this.sidebarflag3 = true;
			} else if (this.$route.name == 'download' && scrollTop <= 800) {
				this.sidebarflag3 = false;
			} else if (this.$route.name == 'about' && scrollTop > 650) {
				this.sidebarflag3 = true;
			} else if (this.$route.name == 'about' && scrollTop <= 650) {
				this.sidebarflag3 = false;
			}
			
			if (this.$route.name == 'start' || this.$route.name == 'home') {
				this.$router.push({
					path: "/home/start"
				})
				this.currentPage = 1
			} else if (this.$route.name == 'product') {
				this.currentPage = 2
			} else if (this.$route.name == 'solution') {
				this.currentPage = 3
			} else if (this.$route.name == 'cooperate') {
				this.currentPage = 4
			} else if (this.$route.name == 'download') {
				this.currentPage = 5
			} else if (this.$route.name == 'about') {
				this.currentPage = 7
			} 
		},
		mounted() {
			// 监听页面滚动事件
			window.addEventListener("scroll", this.scrolling)
		},
		watch: {
			scrollheight : {
				handler(newV, oldV) {
					if (this.$route.name == 'start' && newV > 800) {
						this.sidebarflag3 = true;
					} else if (this.$route.name == 'start' && newV <= 800) {
						this.sidebarflag3 = false;
					} else if (this.$route.name == 'product' && newV > 500) {
						this.sidebarflag3 = true;
					} else if (this.$route.name == 'product' && newV <= 500) {
						this.sidebarflag3 = false;
					} else if (this.$route.name == 'solution' && newV > 500) {
						this.sidebarflag3 = true;
					} else if (this.$route.name == 'solution' && newV <= 500) {
						this.sidebarflag3 = false;
					} else if (this.$route.name == 'cooperate' && newV > 1200) {
						this.sidebarflag3 = true;
					} else if (this.$route.name == 'cooperate' && newV <= 1200) {
						this.sidebarflag3 = false;
					} else if (this.$route.name == 'download' && newV > 800) {
						this.sidebarflag3 = true;
					} else if (this.$route.name == 'download' && newV <= 800) {
						this.sidebarflag3 = false;
					} else if (this.$route.name == 'about' && newV > 650) {
						this.sidebarflag3 = true;
					} else if (this.$route.name == 'about' && newV <= 650) {
						this.sidebarflag3 = false;
					}
				}
			},
		}
	}
</script>

<style lang="less">
	.popperclass {
		position: absolute;
		left: 1338px !important;
		top: 99px;
		font-size: 20px;
		font-family: AlibabaPuHuiTi_2_45_Light;
	}
	
	.popperclass2 {
		position: absolute;
		left: 1538px !important;
		top: 99px;
		font-size: 20px;font-family: AlibabaPuHuiTi_2_45_Light;
	}
</style>

<style lang="less" scoped>
	.el-container {
		min-width: 1920px;
		height: auto;
		
		.el-carousel__item {
		    color: #fff;
				background-color: #eee;
		    font-size: 14px;
		    opacity: 0.5;
		    line-height: 50px;
		    margin: 0;
		  }
		.el-carousel__indicator {
			display: none !important;
		}
		
		.el-header {
			height: 100px !important;
			padding: 0 210px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			z-index: 10001;
			
			.el-menu {
				display: flex;
				height: 99px;
				border-bottom: none;
			}
			
			::v-deep .el-submenu {
				height: 99px;
				display: flex;
				border-bottom: none;
				
				.el-submenu__title {
					height: 99px !important;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 24px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					font-weight: normal;
					color: #020202;
				}
			}
			
			.el-menu-item {
				height: 99px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #020202;
			}
			
			.el-menu-item.is-active {
				color: #000000;
			}
			
			::v-deep.apiview :hover{
				background-color: #ffffff!important;
			}
		}
		
		.sidebar {
			position: fixed; 
			top: 545px; 
			left: 1818px;
			width: 85px;
			height: 85px;
			background: #FFFFFF; 
			display: flex; 
			flex-flow: column; 
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-family: AlibabaPuHuiTi_2_45_Light;
			font-weight: normal;
			color: #010101;
			line-height: 24px;
			border-bottom: 1px solid #E5E5E5;
			z-index: 1002;
		}
		
		.sidebar2 {
			position: fixed; 
			top: 545px; 
			left: 1818px;
			width: 85px;
			height: 85px;
			background: #FFFFFF; 
			display: flex; 
			flex-flow: column; 
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-family: AlibabaPuHuiTi_2_45_Light;
			font-weight: normal;
			color: #010101;
			line-height: 24px;
			border-bottom: 1px solid #E5E5E5;
			z-index: 1002;
		}
		
		.sidebar:hover {
			background: #FF0000;
			color: #FFFFFF;
		}
		
		.showbox1 {
			width: 210px;
			height: 105px;
			background: #FFFFFF;
			box-shadow: 4px 2px 7px 1px rgba(134,134,134,0.77);
			border-radius: 10px;
			position: fixed;
			top: 545px;
			left: 1600px;
			font-size: 18px;
			font-family: AlibabaPuHuiTi_2_45_Light;
			font-weight: normal;
			color: #000000;
			line-height: 30px;
		}
		
		.showbox2 {
			width: 250px;
			height: 185px;
			background: #FFFFFF;
			box-shadow: 4px 2px 7px 1px rgba(134,134,134,0.77);
			border-radius: 20px 10px 10px 10px;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			position: fixed;
			top: 630px;
			left: 1560px;
			font-size: 18px;
			font-family: AlibabaPuHuiTi_2_45_Light;
			font-weight: normal;
			color: #000000;
			line-height: 24px;
		}
		
		.el-footer {
			width: 1920px;
			padding: 0;
			
			.footer {
				width: 1920px;
				height: 300px;
				background: #FF0000;
				
				.footertext1 {
					height: 40px;
					padding: 55px 0 0 214px;
					font-size: 30px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: normal;
					color: #FFFFFF;
				}
				
				.footertext2 {
					font-size: 20px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #FFFFFF;
				}
			}
			
			.footer2 {
				width: 1920px;
				height: 71px;
				background: #B20000;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				white-space:pre;
			}
		}
	}
</style>
