import axios from 'axios'

export function post (config) {
	const instance = axios.create({
		baseURL: 'https://wechat.mingmaoxinxi.com/minmao-admin/',
		// baseURL: 'http://192.168.0.100:80/minmao-admin/',
		timeout: 10000,
		method: 'post',
	})
	
	return instance(config)
}

export function post1 (config) {
	const instance = axios.create({
		baseURL: 'http://localhost:5174/',
		// baseURL: 'http://192.168.10.21:80/minmao-admin/',
		timeout: 10000,
		method: 'get',
	})
	
	return instance(config)
}

export function get (config) {
	const instance = axios.create({
		baseURL: 'https://wechat.mingmaoxinxi.com/minmao-admin/',
		// baseURL: 'http://192.168.18.18:80/minmao-admin/',
		timeout: 10000,
		method: 'get',
	})
	
	return instance(config)
}