<template>
  <div id="app" :style="flag==true? ``:`zoom:${scaleRadio}`">
    <router-view></router-view>
  </div>
</template>

<script>
import Home from './views/home/home.vue'

export default {
  name: 'app',
	data() {
		return {
			scaleRadio: 1,
			flag: false
		}
	},
	 methods: {
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			return flag;
		},
	},
	metaInfo: {
	    // title: '壹本账-这才是老板想要的经营管理账！20年内账管理经验', // 设置title
	    meta: [
	      {
	        name: '关键字',
	        content: '壹本账,一本账,壹本帐,一本帐,经营账,内账,管理账,内账外包,经营账外包,财务软件'
	      },
				{
				  name: 'keywords',
				  content: '壹本账,一本账,壹本帐,一本帐,经营账,内账,管理账,内账外包,经营账外包,财务软件'
				},
	      {
	        name: 'description',
	        content: '壹本账，一款面向老板的管理核算型软件，助力企业加强内部管理、实现数字化转型。跟金蝶、用友等财务软件不一样的是，壹本账是为管理而生：小白也能快速上手，实时生成老板看得懂的管理报表。'
	      },
				{
				  property: 'og:title',
				  content: '壹本账-这才是老板想要的经营管理账！20年内账管理经验'
				},
				{
				  property: 'og:description',
				  content: '壹本账，一款面向老板的管理核算型软件，助力企业加强内部管理、实现数字化转型。跟金蝶、用友等财务软件不一样的是，壹本账是为管理而生：小白也能快速上手，实时生成老板看得懂的管理报表。'
				},
	      {
	        property: 'og:image',
	        content: 'https://14807269.s21i.faiusr.com/4/ABUIABAEGAAgxLKDpgYouJqx0AEw2AQ42wE.png'
	      },
				{
				  name: 'viewport',
				  content: 'width=device-width,initial-scale=1.0maximum-scale=2.0,minimum-scale=0.1,'
				}
	    ]
	  },
		mounted() {
			this.flag = this._isMobile();
			if (!this.flag) {
				let windowWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
				if (windowWidth <= 1920 && windowWidth >= 1366) {
					this.scaleRadio = windowWidth / 1920
				}
			} else {
				let windowWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
				this.scaleRadio = windowWidth / 1920
			}
			
			window.onresize = () => {
				return (() => {
					if (!this.flag) {
						let windowWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
						if (windowWidth <= 1920 && windowWidth >= 1366) {
							this.scaleRadio = windowWidth / 1920
						}
					} else {
						let windowWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
						this.scaleRadio = windowWidth / 1920
					}
				})()
			}
		},
  components: {
    Login: Home
  }
}
</script>

<style>
	
</style>
