import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import ('../views/home/home.vue')
const Start = () => import ('../views/home/component/main.vue')
const Product = () => import ('../views/home/component/product.vue')
const Solution = () => import ('../views/home/component/solution.vue')
const Cooperate = () => import ('../views/home/component/cooperate.vue')
const Download = () => import ('../views/home/component/download.vue')
const Help = () => import ('../views/home/component/help.vue')
const About = () => import ('../views/home/component/about.vue')

Vue.use(VueRouter)

const routes = [
	{
		path: '/home',
		redirect: '/home/start'
	},
	{
		path: '/',
		redirect: '/home/start'
	},
  {
    path: '/home',
		name:	'home',
		component: Home,
		children: [
			{
				path:'start',
				name:'start',
				component:Start
			},
			{
				path:'product',
				name:'product',
				component:Product
			},
			{
				path:'solution',
				name:'solution',
				component:Solution
			},
			{
				path:'cooperate',
				name:'cooperate',
				component:Cooperate
			},
			{
				path:'download',
				name:'download',
				component:Download
			},
			{
				path:'help',
				name:'help',
				component:Help
			},
			{
				path:'about',
				name:'about',
				component:About
			}
		]
	}
]

const router = new VueRouter({
  mode: 'hash',
  base: '/',
  routes
})

export default router
