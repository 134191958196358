import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Encrypt,Decrypt} from './network/encryption.js'
import axios from './network/http'
import './plugins/element.js'
import './assets/css/global.css'
import './assets/fonts/iconfont.css'
import qs from 'qs'
import './assets/fonts/font.css'
import MetaInfo from 'vue-meta-info';

Vue.use(MetaInfo);
Vue.prototype.qs = qs
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$store = store 
Vue.prototype.encrypt = Encrypt 
Vue.prototype.decrypt = Decrypt 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
